import {
  createBrowserRouter,
  matchRoutes,
  useLocation,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  NotFound,
  Home,
  LoginPage,
  AuthPage,
  ProfilePage,
  RootAccountPage,
  RootAccountOrder,
  RootAccountHistory,
  RootAccountDetailTransaction,
  RootAccountDetailPackage,
} from '../pages'
import {
  AUTH_LOGIN,
  HOME,
  PROFILE,
  ORDER,
  HISTORY,
  OVERVIEW,
  DETAIL_TRANSACTION,
  ROOT_ACCOUNT,
  DETAIL_PACKAGE,
} from '../assets/constants/route_path'
import Layout from '../layout'
import PrivateRoute from '../components/private-route'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PermUtils from '../utils/perm-utils'

export const routes = [
  {
    path: '/auth',
    element: <AuthPage />,
    children: [
      {
        path: AUTH_LOGIN,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: HOME,
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: PROFILE,
        element: (
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + OVERVIEW,
        element: (
          <PrivateRoute hasAnyFunctions={[
            PermUtils.canCheckBalanceTkg,
          ]}>
            <RootAccountPage />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + ORDER,
        element: (
          <PrivateRoute hasAnyFunctions={[
            PermUtils.canGetListOrderTkgDashboard,
            PermUtils.canGetListTransactionTkgDashboardByOrderId,
            PermUtils.canTkgByOrderId,
            PermUtils.canTkgByPhone,
            PermUtils.canUploadOrderTkgByExcel,
          ]}>
            <RootAccountOrder />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + HISTORY,
        element: (
          <PrivateRoute hasAnyFunctions={[
            PermUtils.canGetListTransactionTkgDashboardByOrderId,
            PermUtils.canCheckTransactionTkgOnResource,
            PermUtils.canCheckTransactionTkgOnAdapter,
          ]}>
            <RootAccountHistory />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + DETAIL_TRANSACTION,
        element: (
          <PrivateRoute hasAnyFunctions={[
            PermUtils.canGetListTransactionTkgDashboardByOrderId,
            PermUtils.canCheckTransactionTkgOnAdapter,
          ]}>
            <RootAccountDetailTransaction />
          </PrivateRoute>
        )
      },
      {
        path: ROOT_ACCOUNT + DETAIL_PACKAGE,
        element: (
          <PrivateRoute hasAnyFunctions={[
            PermUtils.canGetListPackagesTkgDashboard,
          ]}>
            <RootAccountDetailPackage />
          </PrivateRoute>
        )
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export const useMatchedRoute = () => {
  const location = useLocation()
  const matchedRoutes = matchRoutes(routes, location)

  if (!matchedRoutes || matchedRoutes.length === 0) {
    return undefined
  }

  // Sort by accuracy desc
  matchedRoutes.reverse()

  for (let matchedRoute of matchedRoutes) {
    const matchedRoutePath = matchedRoute.route.path
    // index route will not contain path attr -> get next route (parent route)
    if (matchedRoutePath) {
      return matchedRoutePath
    }
  }

  return undefined
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
export const router = sentryCreateBrowserRouter(routes)
