import { IUser } from "../models/user.model";
import _ from 'lodash';

const canGetListPackagesTkgDashboard = 'GET::/api/partner/mbh-tkg-dashboard/get-list-packages-tkg-dashboard'
const canGetListOrderTkgDashboard = 'GET::/api/partner/mbh-tkg-dashboard/get-list-order-tkg-dashboard'
const canGetListTransactionTkgDashboardByOrderId = 'GET::/api/partner/mbh-tkg-dashboard/get-list-transaction-tkg-dashboard-by-order-id'
const canCheckTransactionTkgOnResource = 'GET::/api/partner/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-resource'
const canCheckTransactionTkgOnAdapter = 'GET::/api/partner/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-adapter'
const canUploadOrderTkgByExcel = 'POST::/api/partner/mbh-tkg-dashboard/upload-order-tkg-dashboard-by-excel'
const canTkgByPhone = 'POST::/api/partner/mbh-tkg-dashboard/tkg-dashboard-by-phone'
const canTkgByOrderId = 'POST::/api/partner/mbh-tkg-dashboard/tkg-dashboard-by-order-id'
const canCheckBalanceTkg = 'GET::/api/partner/mbh-tkg-dashboard/check-balance-tkg'

export const setUserPermissions = (user: IUser) => {
    const userPermission = user.listPermission

    user.canGetListPackagesTkgDashboard = userPermission?.includes(canGetListPackagesTkgDashboard)
    user.canGetListOrderTkgDashboard = userPermission?.includes(canGetListOrderTkgDashboard)
    user.canGetListTransactionTkgDashboardByOrderId = userPermission?.includes(canGetListTransactionTkgDashboardByOrderId)
    user.canCheckTransactionTkgOnResource = userPermission?.includes(canCheckTransactionTkgOnResource)
    user.canCheckTransactionTkgOnAdapter = userPermission?.includes(canCheckTransactionTkgOnAdapter)
    user.canUploadOrderTkgByExcel = userPermission?.includes(canUploadOrderTkgByExcel)
    user.canTkgByPhone = userPermission?.includes(canTkgByPhone)
    user.canTkgByOrderId = userPermission?.includes(canTkgByOrderId)
    user.canCheckBalanceTkg = userPermission?.includes(canCheckBalanceTkg)
    user.canTkgDashboardManagement = user.canGetListPackagesTkgDashboard
        || user.canGetListOrderTkgDashboard
        || user.canGetListTransactionTkgDashboardByOrderId
        || user.canCheckTransactionTkgOnResource
        || user.canCheckTransactionTkgOnAdapter
        || user.canUploadOrderTkgByExcel
        || user.canTkgByPhone
        || user.canTkgByOrderId
        || user.canCheckBalanceTkg
}

const hasAnyAuthority = (functions: string[], hasAnyFunctions: string[]) => {
    if (_.isEmpty(functions)) {
        return false;
    }
    if (hasAnyFunctions.length === 0) {
        return true;
    }
    return hasAnyFunctions.some(auth => functions.includes(auth));
};

const PermUtils = {
    canGetListPackagesTkgDashboard,
    canGetListOrderTkgDashboard,
    canGetListTransactionTkgDashboardByOrderId,
    canCheckTransactionTkgOnResource,
    canCheckTransactionTkgOnAdapter,
    canUploadOrderTkgByExcel,
    canTkgByPhone,
    canTkgByOrderId,
    canCheckBalanceTkg,
    setUserPermissions,
    hasAnyAuthority
}

export default PermUtils
