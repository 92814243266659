import { Breadcrumb } from 'antd'
import { useLocation } from 'react-router-dom'

const routes: Record<string, string> = {
  '/profile': 'Trang cá nhân',
  '/root-account': 'Đăng ký gói cước',
  '/root-account/overview': 'Tổng quan',
  '/root-account/order': 'Đăng ký',
  '/root-account/history': 'Lịch sử giao dịch',
  '/root-account/detail-transaction': 'Chi tiết giao dịch',
  '/root-account/detail-package': 'Danh sách gói cước',
}

const BreadcrumbItem = () => {
  const location = useLocation()

  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const extraBreadcrumbItems = pathSnippets.map((str, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`

    let breadcrumbName = ''
    Object.keys(routes).forEach((pattern) => {
      const regex = new RegExp(pattern)

      if (regex.test(url)) {
        breadcrumbName = routes[pattern]
      }
    })

    return {
      key: url,
      title: breadcrumbName,
    }
  })

  return <Breadcrumb items={extraBreadcrumbItems} />
}
export default BreadcrumbItem
