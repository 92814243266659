const AUTH_LOGIN = '/auth/login'
const HOME = '/'
const PROFILE = '/profile'
const OVERVIEW = '/overview'
const HISTORY = '/history'
const ORDER = '/order'
const DETAIL_TRANSACTION = '/detail-transaction'
const DETAIL_PACKAGE = '/detail-package'
const ROOT_ACCOUNT = '/root-account'

export {
  AUTH_LOGIN,
  PROFILE,
  HOME,
  OVERVIEW,
  HISTORY,
  ORDER,
  DETAIL_TRANSACTION,
  DETAIL_PACKAGE,
  ROOT_ACCOUNT,
}
