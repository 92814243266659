import { Button } from 'antd'
import StatusTag from '../../components/tag/StatusTag'
import userImg from '../../assets/images/user.png'

export default function Avatar({ admin }: any) {
    return (
        <div style={{ textAlign: 'center', margin: 12 }}>
            <img style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: '50%', border: '2px solid #f0f0f0' }} src={userImg} alt='User' />
            <h2>{admin.full_name ? admin.full_name : admin.username}</h2>
            <Button type='text'>
                <StatusTag status={admin.active} />
            </Button>
        </div>
    )
}
