import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Flex, Form, Modal, Progress, Select, Space, TableProps, Tag } from 'antd'
import { EditOutlined, SearchOutlined, UndoOutlined, EyeOutlined } from '@ant-design/icons'
import CustomTable from '../../components/table'
import defaultParams from '../../assets/constants/default_params'
import { toastError } from '../../utils/toast'
import { useLoading } from '../../context/LoadingContext'
import { apiGetListTransactionTkgDashboardByOrderId, apiCheckTransactionTkgDashboardOnAdapter } from '../../api'
import moment from 'moment'
import ButtonBack from '../../components/button/ButtonBack'
import { formatCurrency } from '../../utils/string-utils'
import { useSearchParams } from 'react-router-dom'
import { mbhStatusOptions } from '../../assets/constants/default_options'
import { filterOptionByLabel } from '../../utils/filter-utils'
import ModalView from './ModalView'
import { useSelector } from 'react-redux'
import { authSelector } from '../../redux/slices/authSlice'

export default function RootAccountDetailTransaction() {
    const [tranTkg, setTranTkg] = useState<any>()
    const [progessPercent, setProgessPercent] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [search, setSearch] = useState({
        status: '',
    })
    const [modalView, setModalView] = useState<{ data: any, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [modalCheckAdapter, setModalCheckAdapter] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [dataCheckAdapter, setDataCheckAdapter] = useState<any>({})

    const { setIsLoading } = useLoading()
    const authState = useSelector(authSelector)
    const { account } = authState
    const [formSearch] = Form.useForm()
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId')

    const columns: TableProps<any>['columns'] = [
        {
            key: 'tranId',
            title: 'Mã giao dịch',
            dataIndex: 'tranId',
            render: tranId => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {tranId}
                </span>
            ),
        },
        {
            key: 'customer',
            title: 'Số điện thoại',
            dataIndex: 'customer',
        },
        {
            key: 'dataPack',
            title: 'Tên gói cước',
            dataIndex: 'dataPack',
        },
        {
            key: 'dataType',
            title: 'Loại gói cước',
            dataIndex: 'dataType',
            render: () => '4G/5G'
        },
        // {
        //     key: 'price',
        //     title: 'Giá',
        //     dataIndex: 'price',
        //     render: (price) => (
        //         <span style={{ whiteSpace: 'nowrap' }}>
        //             {formatCurrency(price)}
        //         </span>
        //     ),
        // },
        {
            key: 'updatedAt',
            title: 'Cập nhật',
            dataIndex: 'updatedAt',
            render: (updatedAt) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(updatedAt)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'reqTime',
            title: 'Thời gian tạo',
            dataIndex: 'reqTime',
            render: (reqTime) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {moment(new Date(reqTime * 1000)).format('YYYY-MM-DD hh:mm:ss')}
                </span>
            )
        },
        {
            key: 'status',
            title: 'Trạng thái',
            dataIndex: 'return',
            render: status => renderStatus(status)
        },
        {
            key: 'action',
            title: 'Tác vụ',
            dataIndex: 'action',
            align: 'center',
            render: (_, data: any) => (
                <Space>
                    <Button
                        onClick={() => setModalView({ data, isOpen: true })}
                        icon={<EyeOutlined />}
                        style={{ background: '#289e45', color: 'white' }}
                    >
                        Chi tiết
                    </Button>
                    {account.canCheckTransactionTkgOnAdapter && (
                        <Button
                            onClick={() => setModalCheckAdapter({ data, isOpen: true })}
                            icon={<EditOutlined />}
                            type='primary'
                        >
                            Kiểm tra
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === 'SUCCESS') return <Tag style={style} color="#9b9b05">Thành công</Tag>
        if (state === 'FAIL') return <Tag style={style} color="#c93c3c">Thất bại</Tag>
        if (state === 'ERROR') return <Tag style={style} color="#c93c3c">Lỗi</Tag>
        if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
        return <Tag style={style} color="#2faac6">Đã tạo</Tag>
    }

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionTkgDashboardByOrderId({
                orderId: orderId,
                page: currentPage,
                size: pageSize,
                status: search.status,
            })
            if (resp.data != null) {
                setTranTkg(resp.data)
                const respFull = await apiGetListTransactionTkgDashboardByOrderId({
                    orderId: orderId,
                    page: defaultParams.PAGE,
                    size: defaultParams.MAX_LIMIT,
                })
                if (respFull.data != null) {
                    let totalProgess = 0
                    respFull.data.trans?.map((item: any) => {
                        if (item.return === 'SUCCESS' || item.return === 'FAIL' || item.return === 'ERROR') {
                            totalProgess++
                        }
                    })
                    if (totalProgess > 0) {
                        const percent = Number(totalProgess / respFull.data.total * 100).toFixed(2)
                        setProgessPercent(Number(percent))
                    }
                }
            } else {
                setTranTkg({})
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }, [setIsLoading, currentPage, pageSize, orderId, search])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleSearch = ({ status }: any) => {
        if (status) {
            setSearch({ status })
            setCurrentPage(defaultParams.PAGE)
            setPageSize(defaultParams.LIMIT)
        }
    }

    const handleClearSearch = () => {
        formSearch.resetFields()
        setSearch({
            status: '',
        })
        setCurrentPage(defaultParams.PAGE)
        setPageSize(defaultParams.LIMIT)
    }

    const handleCheckAdapter = async () => {
        try {
            setIsLoading(true)
            const resp = await apiCheckTransactionTkgDashboardOnAdapter({
                transaction_id: modalCheckAdapter.data?.tranId,
            })
            const respData = resp.data.data[0]
            if (resp.status === 200 && respData !== undefined) {
                if (respData.status !== 1) {
                    respData.desc = 'Thành công'
                }
                setDataCheckAdapter(respData)
            } else {
                toastError('Có lỗi xảy ra!')
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
            setIsLoading(false)
        }
    }

    const handleCloseModalCheckAdapter = () => {
        setModalCheckAdapter({ data: null, isOpen: false })
        setDataCheckAdapter({})
    }

    return (
        <div>
            <ButtonBack />
            <h2>Mã đơn hàng: {orderId}</h2>
            <Form form={formSearch} onFinish={handleSearch}>
                <Space style={{ display: 'flex', alignItems: 'start', flexWrap: 'wrap', marginBottom: 20 }}>
                    <Form.Item name='status' label='Trạng thái'>
                        <Select
                            style={{ width: 170 }}
                            showSearch
                            placeholder="Chọn trạng thái"
                            options={mbhStatusOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    <Space>
                        <Button type='primary' htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </Space>
            </Form>
            <Space style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginBottom: 20 }}>
                Đã xử lý: <Flex gap="small" vertical>
                    <Progress style={{ width: 200 }} percent={progessPercent} status="active" />
                </Flex>
            </Space>
            <CustomTable
                namePage='giao dịch'
                columns={columns}
                dataSource={tranTkg?.trans}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={tranTkg?.total || tranTkg?.trans?.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal title='Kiểm tra giao dịch' open={modalCheckAdapter.isOpen}
                onCancel={handleCloseModalCheckAdapter}
                footer={[
                    <Button onClick={handleCheckAdapter}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalCheckAdapter}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Kiểm tra giao dịch <b>{modalCheckAdapter.data?.tranId}</b></p>
                {dataCheckAdapter?.desc && <b>Kết quả: {dataCheckAdapter.desc}</b>}
                <Divider />
            </Modal>
            <ModalView
                modalView={modalView}
                setModalView={setModalView}
            />
        </div>
    )
}
