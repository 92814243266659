import { Divider, Modal, Space, Tag, Typography } from 'antd'
const { Text } = Typography

type FieldModalView = {
    modalView: { data: any | null, isOpen: boolean }
    setModalView: Function
}

export default function ModalView({ modalView, setModalView }: FieldModalView) {
    const renderStatus = (state: string) => {
        const style: object = { width: 100, textAlign: 'center', marginRight: 0 }
        if (state === 'SUCCESS') return <Tag style={style} color="#9b9b05">Thành công</Tag>
        if (state === 'FAIL') return <Tag style={style} color="#c93c3c">Thất bại</Tag>
        if (state === 'ERROR') return <Tag style={style} color="#c93c3c">Lỗi</Tag>
        if (state === 'PROCESSING') return <Tag style={style} color="#1d9a46">Đang xử lý</Tag>
        return <Tag style={style} color="#2faac6">Đã tạo</Tag>
    }

    return (
        <Modal
            title='Chi tiết giao dịch'
            open={modalView.isOpen}
            footer={[]}
            onCancel={() => setModalView({ data: null, isOpen: false })}>
            <Divider />
            <div style={{ marginBottom: 10 }}>
                <Space>
                    <Text strong>Mã đơn hàng:</Text>
                    {modalView.data?.orderId}
                </Space>
            </div>
            <div>
                <Space>
                    <Text strong>Mã giao dịch:</Text>
                    {modalView.data?.tranId}
                </Space>
            </div>
            <Divider />
            <div style={{ marginBottom: 10 }}>
                <Space>
                    <Text strong>Số điện thoại:</Text>
                    {modalView.data?.customer}
                </Space>
            </div>
            <Text strong>Thông tin gói cước:</Text>
            <p>Tên gói: {modalView.data?.packageName}</p>
            <p>Mã gói: {modalView.data?.dataPack}</p>
            {/* <p>Giá: {formatCurrency(Number(modalView.data?.price))}</p> */}
            <p>Loại: 4G/5G</p>
            <Divider />
            <Space>
                <Text strong>Trạng thái:</Text>
                {renderStatus(modalView.data?.return)}
            </Space>
            <Divider />
            <Space>
                <Text strong>Mô tả:</Text>
                {modalView.data?.description}
            </Space>
        </Modal>
    )
}
