import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    apiGetInfoByToken,
    apiLogin,
} from "../../api";
import {
    AUTH_TOKEN_KEY,
    CACHE_PATH_NAME,
    EXPIRED_CACHE_1_DAY,
    USERNAME,
    USER_PROFILE_KEY
} from "../../config/constants";
import { ILogoutMetadata, LogoutType } from "../../models/logout.model";
import { IUser } from "../../models/user.model";
import { removeStorage, setStorage } from "../../utils/storage-utils";
import { ILoginResponse } from "../../models/login.model";
import PermUtils from "../../utils/perm-utils";

const getCacheUser = () => {
    const cacheUser = localStorage.getItem(USER_PROFILE_KEY);
    if (!cacheUser) {
        return {} as IUser;
    }
    const user = JSON.parse(cacheUser);

    PermUtils.setUserPermissions(user);
    return user;
};

const initialState = {
    loading: false,
    isAuthenticated: localStorage.getItem(AUTH_TOKEN_KEY) != null,
    loginSuccess: false,
    loginError: false,
    account: getCacheUser() as IUser,
    logoutMetadata: null as unknown as ILogoutMetadata,
    errorMessage: '',
    updating: false,
    updateSuccess: false,
    isLoginRequiredMfa: '',
};

export const ACTION_TYPES = {
    LOGIN: 'authentication/LOGIN',
    GET_SESSION: 'authentication/GET_SESSION',
    LOGOUT: 'authentication/LOGOUT',
    LOGOUT_CLIENT: 'authentication/LOGOUT_CLIENT',
};

export const login: any = createAsyncThunk(ACTION_TYPES.LOGIN, async (payload: any, thunkAPI) => {
    try {
        const { username, password, cpCode } = payload
        const resp = await apiLogin({ username, password, cpCode })
        const { data } = resp
        const user = data as ILoginResponse
        localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        localStorage.setItem(USERNAME, username);
        return user
    } catch (err: any) {
        console.log("thunkAPI.rejectWithValue", err)
        return thunkAPI.rejectWithValue(err)
    }
})


const cachePathWithIdleSessionTimeOut = (metadata?: ILogoutMetadata) => {
    // eslint-disable-next-line eqeqeq
    if (metadata?.logoutType != undefined && metadata?.logoutType === LogoutType.IDLE_SESSION_TIMEOUT) {
        setStorage(CACHE_PATH_NAME, JSON.stringify(metadata), EXPIRED_CACHE_1_DAY);
    }
    // eslint-disable-next-line eqeqeq
    if (metadata?.logoutType != undefined && metadata.logoutType !== LogoutType.IDLE_SESSION_TIMEOUT) {
        removeStorage(CACHE_PATH_NAME);
    }
};

const clearUserCacheData = () => {
    if (localStorage.getItem(AUTH_TOKEN_KEY)) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
    }
    if (sessionStorage.getItem(AUTH_TOKEN_KEY)) {
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
    }
    if (localStorage.getItem(USER_PROFILE_KEY)) {
        localStorage.removeItem(USER_PROFILE_KEY);
    }
};
// Logout user in both client side and server side.
export const logout: any = createAsyncThunk(ACTION_TYPES.LOGOUT, async (payload: any, thunkAPI) => {
    try {
        let metadataObject = payload as ILogoutMetadata;
        try {
            cachePathWithIdleSessionTimeOut(metadataObject);
            // await apiLogout({logoutType: metadataObject.logoutType});
        } catch (_) {
        } finally {
            clearUserCacheData();
        }
        return metadataObject
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err)
    }
})

export const logoutClient: any = createAsyncThunk(ACTION_TYPES.LOGOUT_CLIENT, async (payload: any, thunkAPI) => {
    try {
        let metadataObject: ILogoutMetadata = {}
        clearUserCacheData();
        return metadataObject
    } catch (err: any) {
        return thunkAPI.rejectWithValue(err)
    }
})

export const getSession: any = createAsyncThunk(ACTION_TYPES.GET_SESSION, async (payload: any, thunkAPI) => {
    try {
        const username = localStorage.getItem(USERNAME)
        const token = localStorage.getItem(AUTH_TOKEN_KEY)
        console.log(USERNAME, username)
        if (username && token) {
            const { data } = await apiGetInfoByToken()
            const user = data as IUser
            user.listPermission = []
            if (user.role !== undefined && Array.isArray(user.role?.permissions)) {
                user.listPermission = user.role?.permissions?.map(item => `${item.action}::${item.resource}`)
            }
            PermUtils.setUserPermissions(user)
            localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(user));
            return { data: user };
        }
        return null

    } catch (err: any) {
        return thunkAPI.rejectWithValue(err)
    }
})

const auth = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        getSession: (state) => {
            console.log("======getSession", state, state)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            return {
                ...state,
                loading: true,
                loginSuccess: false,
                loginError: false,
                errorMessage: '',
            }

        })
        builder.addCase(login.fulfilled, (state) => {
            return {
                ...state,
                loading: false,
                loginError: false,
                loginSuccess: true,
            }

        })


        builder.addCase(login.rejected, (state, action) => {
            console.log("action", action)
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                loginError: true,
                errorMessage: "FAIL"
            }

        })

        builder.addCase(getSession.pending, (state) => {
            return {
                ...state,
                loading: true,
                loginSuccess: false,
                loginError: false,
                errorMessage: "",
            }
        })

        builder.addCase(getSession.fulfilled, (state, action) => {
            console.log("getSession", action)
            let isAuthenticated =
                action.payload && action.payload.data;
            return {
                ...state,
                isAuthenticated,
                loading: false,
                account: action.payload.data,
            }
        })

        builder.addCase(getSession.rejected, (state, action) => {
            console.log("jhhjhjjhhjhjhj", action)
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                loginError: true,
                errorMessage: action.payload.data.message,
                isLoginRequiredMfa: action.payload.data.statusCode,
            }
        })

        builder.addCase(logout.fulfilled, (state, action) => {
            return {
                ...state,
                isAuthenticated: false,
                logoutMetadata: action.payload,
            }
        })

        builder.addCase(logoutClient.fulfilled, (state, action) => {
            return {
                ...state,
                isAuthenticated: false,
                logoutMetadata: action.payload,
            }
        })

    }
})

const { reducer } = auth

export const authSelector = (state: any) => state.auth

export default reducer
