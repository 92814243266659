import React, { useCallback, useEffect, useState } from 'react'
import { getSession } from '../../redux/slices/authSlice'
import { useDispatch } from 'react-redux'
import { Card, Col, Row, Tabs, TabsProps } from 'antd'
// import Overview from './Overview'
import { toastError } from '../../utils/toast'
import ChangePassword from './ChangePassword'
import { IUser } from '../../models/user.model'
import { useLoading } from "../../context/LoadingContext"
import Avatar from './Avatar'

const ProfilePage: React.FC = () => {
  const [admin, setAdmin] = useState<IUser>()
  const dispatch = useDispatch()
  const { setIsLoading } = useLoading()

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const sessionResp = await dispatch(getSession()).unwrap()
      setAdmin(sessionResp.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      toastError('Có lỗi xảy ra! Vui lòng kiểm tra lại')
      setIsLoading(false)
    }
  }, [dispatch, setIsLoading])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const itemsTab: TabsProps['items'] = !admin ? [] : [
    {
      key: 'overview',
      label: 'Tổng quan',
      children: <Avatar admin={admin} />,
    },
    {
      key: 'change-password',
      label: 'Đổi mật khẩu',
      children: <ChangePassword admin={admin} />,
    },
  ]

  return (
    <div className='profile-container'>
      <Card>
        {admin && (
          <Row justify='start'>
            <Col xs={24} md={24} lg={16}>
              <div style={{ maxWidth: 500 }}>
                <Tabs defaultActiveKey='overview' items={itemsTab} ></Tabs>
              </div>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  )
}

export default ProfilePage
