export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL
export const APP_VERSION = process.env.REACT_APP_VERSION
export const ADMIN_USERNAME = process.env.REACT_APP_ADMIN_USERNAME || 'admin'
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
export const GOOGLE_CAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY || 'GOOGLE_CAPTCHA_SITE_KEY'
export const MAX_FAIL_TIMES = Number(process.env.REACT_APP_GOOGLE_MAX_FAIL_TIMES) || 3
export const EXPIRED = '-expired'
export const CACHE_PATH_NAME = 'jhi-cachePathName'
export const AUTH_TOKEN_KEY = 'jhi-authenticationToken'
export const USER_PROFILE_KEY = 'jhi-userToken'
export const IDLE_SESSION_TIMEOUT_MINUTES = 24 * 60
export const EXPIRED_CACHE_1_DAY = 24 * 60 * 60 * 1000
export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm'
export const USERNAME = 'username'