export const userStatusOptions = [
    { value: true, label: 'Hoạt động' },
    { value: false, label: 'Đang khoá' },
]

export const orderStatusOptions = [
    { value: 'COMPLETED', label: 'Hoàn thành' },
    { value: 'CANCELLED', label: 'Đã huỷ bỏ' },
    { value: 'CREATED', label: 'Đã tạo' },
    { value: 'PROCESSING', label: 'Đang xử lý' },
    { value: 'PENDING', label: 'Đang nạp excel' },
]

export const mbhStatusOptions = [
    { value: 'SUCCESS', label: 'Thành công' },
    { value: 'FAIL', label: 'Thất bại' },
    { value: 'ERROR', label: 'Lỗi' },
    { value: 'CREATED', label: 'Đã tạo' },
    { value: 'PROCESSING', label: 'Đang xử lý' },
]

export const packageTkgOptions = [
    { value: 'M2M200T', label: 'M2M200T - 3G - 200,000 đ/30 ngày' },
    { value: 'M2M70T', label: 'M2M70T - 3G - 70,000 đ/30 ngày' },
    { value: 'M2M300T', label: 'M2M300T - 3G - 300,000 đ/30 ngày' },
    { value: 'M2M50T', label: 'M2M50T - 3G - 50,000 đ/30 ngày' },
    { value: 'M2M250T', label: 'M2M250T - 3G - 250,000 đ/30 ngày' },
    { value: '6M128', label: '6M128 - 3G - 30,000 đ/180 ngày' },
    { value: 'M2M120T', label: 'M2M120T - 3G - 120,000 đ/30 ngày' },
    { value: '12M128', label: '12M128 - 3G - 60,000 đ/360 ngày' },
    { value: 'M128', label: 'M128 - 3G - 5,000 đ/30 ngày' },
]