import APIUtils from './base'
import { ILoginParams } from '../models/login.model'
import { IChangePasswordParams } from '../models/user.model'
import { overrideAxiosDefaultConfig } from './config'
import { setupAxiosInterceptors } from './interceptor'

export const apiLogin = (params: ILoginParams) => APIUtils.post('/partner/auth/login', params)
export const apiGetInfoByToken = () => APIUtils.get('/partner/auth/info')
export const apiChangePassword = (params: IChangePasswordParams) => APIUtils.patch('/partner/auth/password', params)
export const apiGetListUsers = () => APIUtils.get('/partner/auth/get-list-users')

export const apiUploadOrderTkgDashboardByExcel = (formData?: FormData, desc?: string) => (
    APIUtils.post(
        `/partner/mbh-tkg-dashboard/upload-order-tkg-dashboard-by-excel?description=${desc}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
    )
)
export const apiTkgDashboardByPhone = (params?: any) => APIUtils.post('/partner/mbh-tkg-dashboard/tkg-dashboard-by-phone', params)
export const apiTkgDashboardByOrderId = (params?: any) => APIUtils.post('/partner/mbh-tkg-dashboard/tkg-dashboard-by-order-id', params)
export const apiCheckBalanceTkgDashboard = (params?: any) => APIUtils.get('/partner/mbh-tkg-dashboard/check-balance-tkg-dashboard', params)
export const apiGetListOrderTkgDashboard = (params?: any) => APIUtils.get('/partner/mbh-tkg-dashboard/get-list-order-tkg-dashboard', params)
export const apiGetListTransactionTkgDashboardByOrderId = (params?: any) => APIUtils.get('/partner/mbh-tkg-dashboard/get-list-transaction-tkg-dashboard-by-order-id', params)
export const apiCheckTransactionTkgDashboardOnResource = (params?: any) => APIUtils.get('/partner/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-resource', params)
export const apiCheckTransactionTkgDashboardOnAdapter = (params?: any) => APIUtils.get('/partner/mbh-tkg-dashboard/check-transaction-tkg-dashboard-on-adapter', params)
export const apiGetListPackagesTkgDashboard = (params?: any) => APIUtils.get('/partner/mbh-tkg-dashboard/get-list-packages-tkg-dashboard', params)

export const settingAPI = ({ onUnauthenticatedCallback }: { onUnauthenticatedCallback: Function }) => {
    overrideAxiosDefaultConfig()
    setupAxiosInterceptors(onUnauthenticatedCallback)
}
